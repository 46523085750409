import { queryOne } from '../shared/technical/rest/restApi';
import { HeatPumpData } from './HeatPumpData';
import { AxiosInstance } from 'axios';

export function getHeatPumpData(customAxios: AxiosInstance, year: number | string): Promise<HeatPumpData> {
  return queryOne<HeatPumpData>(customAxios, 'heat-pump', undefined, 'year=' + year);
}

export function getYearsWithHeatPump(customAxios: AxiosInstance) {
  return queryOne<number[]>(customAxios, 'heat-pump/years');
}
